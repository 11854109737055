import {Api} from './config/request';

const getTeachers = (params) => (Api.getFliveRequest(`teachers/${params}`,true))
const getSearchResult = (params) => (Api.getGlobalSearchRequest(`global-search/v1/?${params}`,false))
const getLevels = () => (Api.getRequest(`misc/v1/level/`,false))

const getClassmates = () => Api.getRequest("teacher/v1/school-class-children/");
const createAssignment = (payload) => Api.postRequest("assignment/v1/teacher/",payload)
const getAllAssignments = (offset) =>
  Api.getSearchRequest(`assignment/v1/teacher/?offset=${offset}`);
const deactivateAssignment = (payload) => Api.putRequest(`assignment/v1/teacher/${payload.id}/`, payload)
const getGuidedPathUnits = (params) => Api.getRequest(`teacher/v1/unit/?grade=${params.grade}&school_class_id=${params.section}`)
const updateGuidedPathUnit = (payload) => Api.putRequest(`teacher/v1/unit/`,payload)

export const TeacherApiCalls = {
    getTeachers,
    getSearchResult,
    getLevels,
    getClassmates,
    createAssignment,
    getAllAssignments,
    deactivateAssignment,
    getGuidedPathUnits,
    updateGuidedPathUnit
}